import "./Home.css";
export default function Home() {
  return (
    <div className="home">
      <img
        src="../HoneyBeeOutline.png"
        alt="Hand drawn bee"
        height={"50%"}
        width={"50%"}
      />
      <p align={"center"}>Kaks eriilmelist mesilat -</p>
      <p align={"center"}>
        linnamesilased Tallinnas Pirital ja metsikud mesilased Lahemaal
        Kasispeal.
      </p>
      <p align={"center"}>
        * Põhjala (soome keeles Pohjola) on soome ja eesti mütoloogias
        põhjapoolne maa. Just selline, kus meie mesilased toimetavad.
      </p>
    </div>
  );
}

import "./App.css";
import { BrowserRouter, Route, NavLink, Routes } from "react-router-dom";

// page components
import Home from "./pages/Home";
import Dna2022tln from "./pages/Dna2022tln";
import Contact from "./pages/Contact";
import Dna2021tln from "./pages/Dna2021tln";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <nav>
          <h1>
            <NavLink exact to="/">
              Põhjala mesilased
            </NavLink>
          </h1>
          <NavLink to="/dna2021tln">DNA2021TLN</NavLink>
          <NavLink to="/dna2022tln">DNA2022TLN</NavLink>
          <NavLink to="/contact">KONTAKT</NavLink>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dna2021tln" element={<Dna2021tln />} />
          <Route path="/dna2022tln" element={<Dna2022tln />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

export default function Dna2022tln() {
  return (
    <div>
      <iframe
        frameBorder="0"
        width={"100%"}
        height={"1000px"}
        title={"DNA2022TLN"}
        src="../DNA2022.html"
      ></iframe>
    </div>
  );
}

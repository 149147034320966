export default function Dna2021tln() {
  return (
    <div>
      <iframe
        frameBorder="0"
        width={"100%"}
        height={"1000px"}
        title={"DNA2021TLN"}
        src="../DNA2021.html"
      ></iframe>
    </div>
  );
}
